import React from 'react';
import SectionWrapper from './SectionWrapper';
import ResponsiveImage from './ResponsiveImage';

const services = [
  {
    title: "Wartung",
    description: "Unsere Wartungsdienste für Kleinkläranlagen gewährleisten eine optimale Funktionalität und Langlebigkeit. Wir bieten regelmäßige Inspektionen und schnelle Problemlösungen, um Ihre Anlage effizient zu halten.",
    image: "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727725717/BioklaeranlagenGilge/kleinklearanlage_ll7nlq.jpg"
  },
  {
    title: "Planung und Bau",
    description: "Wir begleiten Sie von der Planung bis zum fertigen Projekt. Wir führen die Nachrüstung vorhandener Behälter mit Klärtechnik aus. Des Weiteren sind wir Ihr Partner beim Neubau von Komplettanlagen bis 50 EW.",
    image: "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654780/BioklaeranlagenGilge/20240920_093719_sf09ns.jpg"
  },
  {
    title: "Dichtheitsprüfung",
    description: "Dichtheitsprüfungen von Kleinkläranlagen werden zeitnah und unkompliziert durchgeführt. Auch das Einreichen der nötigen Prüfungsunterlagen bei der jeweiligen Wasserbehörde wird durch uns erledigt.",
    image: "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727728593/BioklaeranlagenGilge/624544bc-c028-48a5-8208-c045567991e6.png"
  },
  {
    title: "Sanierung und Abdichten",
    description: "Die Sanierung von undichten Bestandsbehältern ist seit Jahren unser Fachgebiet. Schäden und Risse am Betonkörper können durch uns verschlossen werden. Wir versiegeln selbst poröse Wandungen neu.",
    image: "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727725715/BioklaeranlagenGilge/sanierung2_zwnhse.jpg"
  }
];

function Services() {
  return (
    <section className="py-16" id="services">
      <SectionWrapper className='bg-gray-100 rounded-md'>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-primary mb-12 text-center">Unsere Leistungen</h2>
          {services.map((service, index) => (
            <div key={index} className={`flex flex-col md:flex-row items-center mb-12 ${index % 2 !== 0 ? 'md:flex-row-reverse' : ''}`}>
              <div className="md:w-1/2 mb-8 md:mb-0">
                <h3 className="text-2xl font-bold mb-4 text-secondary">{service.title}</h3>
                <p className="text-gray-700">{service.description}</p>
              </div>
              <div className="md:w-1/2 md:px-8">
                <ResponsiveImage src={service.image} alt={service.title} />
              </div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    </section>
  );
}

export default Services;