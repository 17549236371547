import React, { useEffect } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-share.css';
import '../styles.scss';
import SectionWrapper from './SectionWrapper';

const Gallery = ({ images }) => {
  useEffect(() => {
    const container = document.querySelector('.masonry-gallery-demo');
    if (container) {
      const msnry = new Masonry(container, {
        itemSelector: '.lg-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
      });

      imagesLoaded(container).on('progress', () => {
        msnry.layout();
      });
    }
  }, []);

  return (
    <section className="">
       <SectionWrapper >
    <div className="masonry-gallery-demo">
      <div className="grid-sizer"></div>
      <LightGallery plugins={[lgZoom, lgShare]}>
        {images.map((image, index) => (
          <a href={image} key={index} className="lg-item">
            <img src={image} alt={`Gallery item ${index + 1}`} />
          </a>
        ))}
      </LightGallery>
    </div>
    </SectionWrapper>
    </section>
  );
};

export default Gallery;