import React from 'react';

function ResponsiveImage({ src, alt, className }) {
  return (
    <div className={`max-w-full h-auto ${className}`}>
      <img
        src={src}
        alt={alt}
        className="w-full h-auto max-w-md mx-auto rounded-lg shadow-lg"
      />
    </div>
  );
}

export default ResponsiveImage;