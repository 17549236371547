import React, { useState } from 'react';
import Gallery from '../components/Gallery';
import SectionWrapper from '../components/SectionWrapper';

const projects = [
  {
    title: '25 EW Anlage in Klein Belitz',
    images: [
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654781/BioklaeranlagenGilge/20240920_093342_jyhlwa.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654781/BioklaeranlagenGilge/20240920_101435_rggktk.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654781/BioklaeranlagenGilge/20240920_114754_ihoh2w.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654776/BioklaeranlagenGilge/20240920_122207_p2h3j4.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654776/BioklaeranlagenGilge/20240920_093725_s10tce.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654776/BioklaeranlagenGilge/20240920_080706_ictb88.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654775/BioklaeranlagenGilge/20240920_122156_uzocx8.jpg",
      "https://res.cloudinary.com/dcjd8htwp/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1727654774/BioklaeranlagenGilge/20240920_101442_hjzk1n.jpg",

    ],
  }
];
function Galerie() {
  const [openAccordions, setOpenAccordions] = useState([]);

  const toggleAccordion = (index) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
  <section>
    <SectionWrapper>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-extrabold text-center text-primary mb-12">Unsere Galerie</h1>
        {projects.map((project, index) => (
          <div key={index} className="mb-12 shadow-lg rounded-lg overflow-hidden">
            <button
              onClick={() => toggleAccordion(index)}
              className="text-2xl font-semibold w-full text-left bg-primary text-white p-6 hover:bg-gradient-to-l transition duration-300"
            >
              {project.title}
            </button>
            {openAccordions.includes(index) && (
              <div className="mt-4 p-4 bg-white">
                <Gallery images={project.images} />
              </div>
            )}
          </div>
        ))}
      </div>
    </SectionWrapper>
  </section>
  );
}

export default Galerie;