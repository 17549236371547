import React, { useState, useRef } from 'react';
import SectionWrapper from './SectionWrapper';
import emailjs from '@emailjs/browser';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    message: ''
  });

  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then((result) => {
      console.log('Email sent:', result.text);
      alert('Nachricht erfolgreich gesendet!');
    }, (error) => {
      console.log('Email error:', error.text);
      alert('Fehler beim Senden der Nachricht.');
    });

    setFormData({ name: '', address: '', phone: '', message: '' });
  };

  return (
    <SectionWrapper className="bg-gray-100 py-12 rounded-md">
      <div className="max-w-4xl mx-auto px-4" id="contact">
        <h2 className="text-3xl font-bold mb-8 text-center">Kontaktieren Sie uns</h2>
        <div className="flex flex-col md:flex-row items-start">
          <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
            <h3 className="text-xl font-bold mb-4">Unsere Kontaktdaten</h3>
            <p className="text-gray-700">
              Biokläranlagen Gilge<br />
              Oberhof 1a<br />
              18190 Sanitz<br />
              Telefon: <a href="tel:+4938209499923" className="text-primary hover:underline">038209 49 99 23</a><br />
              E-Mail: <a href="mailto:info@bioklaeranlagen-gilge.de" className="text-primary hover:underline">info@bioklaeranlagen-gilge.de</a>
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <form ref={form} onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Telefonnummer</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Nachricht</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Absenden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
}

export default ContactForm;