import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import Galerie from './pages/Galerie';
import ContactForm from './components/ContactForm';
import './index.css';

function App() {
  return (
    <Router>
      <div className="overflow-x-hidden" id="home">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/galerie" element={<Galerie />} />
        </Routes>
        <ContactForm />
        <Footer />
      </div>
    </Router>
  );
}

export default App;